import { SocialIcon } from "react-social-icons";
import "./home.css";
import main_logo from "./hw_feature_logo.png";

export default function Home() {
  return (
    <>
      <div className="App">
        <body className="App-body">
          <img src={main_logo} alt="main_logo" />
          <p>
            <a href="https://play.google.com/store/apps/details?id=com.nepherius.homework_wizard&utm_source=homeworkwizard-website&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png"
                style={{ width: 150, height: 60 }}
              />
            </a>
          </p>
          <a
            href="https://www.producthunt.com/posts/homework-wizard?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-homework&#0045;wizard"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=376800&theme=dark"
              alt="Homework&#0032;Wizard - Get&#0032;homework&#0032;help&#0032;with&#0032;the&#0032;power&#0032;of&#0032;Artificial&#0032;Intelligence&#0046; | Product Hunt"
              style={{ width: 150, height: 60 }}
            />
          </a>
          <div className="social-div">
            <SocialIcon
              url="https://twitter.com/homeworkwizard1"
              style={{ width: 30, height: 30 }}
            />
            <SocialIcon
              url="https://facebook.com/homeworkwizardai"
              style={{ width: 30, height: 30 }}
            />
            <SocialIcon
              url="https://reddit.com/user/Homework_Wizard"
              style={{ width: 30, height: 30 }}
            />
          </div>
          <p>
            <a className="privacy-link" href={`/privacy`}>
              Privacy Policy
            </a>
          </p>
        </body>
      </div>
    </>
  );
}
